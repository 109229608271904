import React from 'react'
import { Prologue } from './Gallery/Prologue'
import { About } from './About'

export const Home = () => {
    return (
        <>
            <About/>
            <Prologue/>
        </>
    )
}
