//poDell === portrait + modell
const fImages = [
    {
        id: 0,
        src: 'https://i.postimg.cc/QxGTJfjh/19.jpg',
        type: 'landscape'
    },
    {
        id: 1,
        src: 'https://i.postimg.cc/GpQNzRJ7/IMG-4969.jpg',
        type: 'fest'
    },
    {
        id: 2,
        src: 'https://i.postimg.cc/9f2YZz8q/IMG-6589.jpg',
        type: 'poDell'
    },
    {
        id: 3,
        src: 'https://i.postimg.cc/nhKM2x01/5.jpg',
        type: 'gastro'
    },
    {
        id: 89,
        src: 'https://i.postimg.cc/zvn3Q1q5/27.jpg',
        type: 'event'
    }
]

export default fImages;